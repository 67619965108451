export default {
	items: [
		{
			name: 'Dashboard',
			url: '/dashboard',
			icon: 'icon-speedometer',
			badge: {
				variant: 'primary'
			}
		},
		{
			name: 'Account Setup',
			url: '/setup',
			icon: 'icon-settings',
			children: [
				{
					name: 'Company',
					url: '/company',
					icon: 'icon-home'
				},
				{
					name: 'Storage Location',
					url: '/location',
					icon: 'icon-location-pin'
				},
				{
					name: 'User',
					url: '/user',
					icon: 'icon-user'
				},
				{
					name: 'Connection',
					url: '/connection',
					icon: 'icon-globe'
				},
				{
					name: 'Transportation',
					url: '/transportation',
					icon: 'icon-plane'
				},
			]
		},
		{
			name: 'Asset Management',
			url: '/asset',
			icon: 'icon-layers',
			children: [
				{
					name: 'Asset Type',
					url: '/asset-type',
					icon: 'icon-layers'
				},
				{
					name: 'Asset',
					url: '/asset',
					icon: 'icon-star'
				},
				{
					name: 'Asset Pool Distribution',
					url: '/asset-pool-distribution',
					icon: 'icon-grid'
				},
				{
					name: 'Sticker Generator',
					url: '/sticker-generator',
					icon: 'icon-support'
				},
				{
					name: 'Asset Issuance',
					url: '/asset-issuance',
					icon: 'icon-drawer'
				},
			]
		},
		{
			name: 'Transactions',
			url: '/transaction',
			icon: 'icon-target',
			children: [
				{
					name: 'Inventory Session',
					url: '/inventory',
					icon: 'icon-book-open'
				},
				{
					name: 'Dispatch',
					url: '/dispatch',
					icon: 'icon-cursor'
				},
				{
					name: 'Receipt',
					url: '/receipt',
					icon: 'icon-pencil'
				}
			]
		},
		{
			name: 'Reports',
			url: '/reports',
			icon: 'icon-graph',
			children: [
				{
					name: 'Inventory Summary',
					url: '/inventory-summary',
					icon: 'icon-graph'
				},
				{
					name: 'User Inventory Scans',
					url: '/user-inventory-scans',
					icon: 'icon-graph'
				},
				{
					name: 'Assets With Truckers',
					url: '/assets-with-truckers',
					icon: 'icon-graph'
				},
				{
					name: 'Asset Movement',
					url: '/asset-movement-summary',
					icon: 'icon-graph'
				},
				{
					name: 'Asset History Log',
					url: '/asset-history-log',
					icon: 'icon-graph'
				}
			]
		},
		{
			name: 'Support',
			url: '/support',
			icon: 'icon-support',
			children: [
				{
					name: 'Transfer Company',
					url: '/transfer-company-requests',
					icon: 'icon-support'
				},
				{
					name: 'User Session Logs',
					url: '/user-session-logs',
					icon: 'icon-support'
				}
			]
		},
		{
			name: 'Data Importer',
			url: '/data-importer',
			icon: 'icon-docs',
			children: [
				{
					name: 'Company Importer',
					url: '/company-importer',
					icon: 'icon-docs'
				},
				{
					name: 'Location Importer',
					url: '/storage-location-importer',
					icon: 'icon-docs'
				},
				{
					name: 'User Importer',
					url: '/user-importer',
					icon: 'icon-docs'
				},
				{
					name: 'Asset Importer',
					url: '/asset-importer',
					icon: 'icon-docs'
				},
				{
					name: 'Transportation Importer',
					url: '/transportation-importer',
					icon: 'icon-docs'
				},
				{
					name: 'Dispatch Importer',
					url: '/dispatch-importer',
					icon: 'icon-docs'
				}
			]
		}
	]
};
