<template>
	<div class="app">
		<AppHeader fixed>
			<SidebarToggler class="d-lg-none" display="md" mobile />
			<b-link class="navbar-brand" to="/dashboard">
				<img class="navbar-brand-full" src="img/logo.png" width="89" height="37" alt="ARES Logo" />
				<img class="navbar-brand-minimized" src="img/logo-sygnet.png" width="20" height="32" alt="ARES Logo" />
			</b-link>
			<SidebarToggler class="d-md-down-none" display="lg" />
			<b-navbar-nav class="ml-auto">
				<Notifications />
				<DefaultHeaderDropdownAccnt :username="username" :userCompany="userCompany" />
			</b-navbar-nav>
		</AppHeader>
		<div class="app-body">
			<AppSidebar fixed>
				<SidebarHeader />
				<SidebarForm />
				<SidebarNav :navItems="nav"></SidebarNav>
				<SidebarFooter />
				<SidebarMinimizer />
			</AppSidebar>
			<main class="main">
				<Breadcrumb :list="list" class="list" />
				<div class="container-fluid">
					<router-view></router-view>
				</div>
			</main>
		</div>
		<TheFooter class="footer">
			<!--footer-->
			<div class="ml-auto">
				<a href="https://www.ayun.ph" class="footer-text">Ayun! Asset-Based</a>
				<span class="ml-1">&copy; {{ currentYear }}
					<img src="img/tawitech_logo_white.png" width="auto" height="40" />
				</span>
			</div>
		</TheFooter>

		<!-- Modals -->
		<update-credentials />
	</div>
</template>

<script>
import nav from '@/containers/menu/_nav';
import {
	Header as AppHeader,
	SidebarToggler,
	Sidebar as AppSidebar,
	SidebarFooter,
	SidebarForm,
	SidebarHeader,
	SidebarMinimizer,
	SidebarNav,
	Aside as AppAside,
	AsideToggler,
	Footer as TheFooter,
	Breadcrumb,
} from '@coreui/vue';
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt';
import Notifications from '../views/commons/notification/Notifications';
import UpdateCredentials from '@/views/account/UpdateCredentials';
import _ from 'lodash';

export default {
	name: 'full',
	components: {
		AsideToggler,
		AppHeader,
		AppSidebar,
		AppAside,
		TheFooter,
		Breadcrumb,
		DefaultHeaderDropdownAccnt,
		SidebarForm,
		SidebarFooter,
		SidebarToggler,
		SidebarHeader,
		SidebarNav,
		SidebarMinimizer,
		Notifications,
		UpdateCredentials,
	},
	data() {
		return {
			nav: nav.items,

			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			isViewer: this.$store.getters.isViewer,
		};
	},
	computed: {
		currentYear() {
			return new Date().getFullYear();
		},
		name() {
			return this.$route.name;
		},
		list() {
			return this.$route.matched.filter(
				(route) => route.name || route.meta.label
			);
		},
		username() {
			if (this.$store.getters.email) {
				return this.$store.getters.email;
			}
			return '';
		},
		userCompany() {
			if (this.$store.getters.email) {
				return this.$store.getters.loggedUser.company;
			}
			return '';
		},
		isNewUser() {
			const currUser = this.$store.getters.loggedUser;
			return currUser.isNewUser ? true : false;
		},
		isManager() {
			return this.loggedUser.type === 'Manager';
		}
	},
	async mounted() {
		// if a superadmin user trying to access a non-admin page return to admin dashboard
		if (this.$store.getters.isSuperAdmin) {
			this.$router.push('/admin/dashboard');
			this.$toaster.warning('You are not allowed to access this page.');
		} else {
			if (this.isNewUser) {
				this.$bvModal.show('update-credentials');
			}
		}

		this.nav = await this.filterNavigations();
	},
	methods: {
		async filterNavigations() {
			let filteredNav = this.nav;

			let permissions = this.loggedUserCompany.permissions;

			if (_.isEmpty(permissions)) {
				this.$toaster.warning('You are not allowed to access this page.');
				await this.$store.dispatch('logout');
				let previousState = this.$store.getters.previousState;
				if (!_.isEmpty(previousState)) {
					this.$store.replaceState(previousState);
				}

				this.$router.push('/login');
				return;
			}

			if (!permissions.cms) {
				// do nothing
			}

			if (!permissions.inventory && !permissions.dispatch) {
				// Remove 'Inventory Summary' Page
				this.removePageAccess(filteredNav, 'Reports', 'Inventory Summary');
			}

			if (!permissions.inventory) {
				// Remove 'Asset Issuance' Page
				this.removePageAccess(
					filteredNav,
					'Asset Management',
					'Asset Issuance'
				);

				// Remove 'Inventory Session' Page
				this.removePageAccess(filteredNav, 'Transactions', 'Inventory Session');

				// Remove 'User Inventory Scans Report' Page
				this.removePageAccess(filteredNav, 'Reports', 'User Inventory Scans');
			}

			if (!permissions.dispatch) {
				// Remove 'Connection' Page
				this.removePageAccess(filteredNav, 'Account Setup', 'Connection');
				// Remove 'Transportation' Page
				this.removePageAccess(filteredNav, 'Account Setup', 'Transportation');

				// Remove 'Dispatch' Page
				this.removePageAccess(filteredNav, 'Transactions', 'Dispatch');
				// Remove 'Receipt' Page
				this.removePageAccess(filteredNav, 'Transactions', 'Receipt');

				// Remove 'Assets With Truckers' Page
				this.removePageAccess(filteredNav, 'Reports', 'Assets With Truckers');
				// Remove 'Asset Movement' Page
				this.removePageAccess(filteredNav, 'Reports', 'Asset Movement');

				// Remove 'Dispatch Importer' Page
				this.removePageAccess(
					filteredNav,
					'Data Importer',
					'Dispatch Importer'
				);
				// Remove 'Transportation Importer' Page
				this.removePageAccess(
					filteredNav,
					'Data Importer',
					'Transportation Importer'
				);
			}

			if (!permissions.sticker) {
				// Remove Sticker Generator
				this.removePageAccess(
					filteredNav,
					'Asset Management',
					'Sticker Generator'
				);
			}

			if (this.isViewer) {
				// Remove all pages under Data Importer
				this.removePageAccess(filteredNav, 'Data Importer', 'Company Importer');
				this.removePageAccess(
					filteredNav,
					'Data Importer',
					'Location Importer'
				);
				this.removePageAccess(filteredNav, 'Data Importer', 'User Importer');
				this.removePageAccess(filteredNav, 'Data Importer', 'Asset Importer');
				this.removePageAccess(
					filteredNav,
					'Data Importer',
					'Transportation Importer'
				);
				this.removePageAccess(
					filteredNav,
					'Data Importer',
					'Dispatch Importer'
				);
			}

			if (!this.isManager) {
				this.removePageAccess(
					filteredNav,
					'Support',
					'User Session Logs'
				);
			}

			return filteredNav;
		},
		removePageAccess(filteredNav, sectionTitle, pageTitle) {
			let index = _.findIndex(filteredNav, (o) => {
				return o.name === sectionTitle;
			});

			// Remove Page from the Section
			let selNav = filteredNav[index];
			selNav.children = _.filter(selNav.children, (o) => {
				return o.name !== pageTitle;
			});

			// Remove Section for empty page options
			if (_.isEmpty(selNav.children)) {
				_.remove(filteredNav, (o) => {
					return o.name === sectionTitle;
				});
			}
		},
	},
};
</script>
<style>
.list {
	color: #122c91;
}
</style>
